import React from 'react'
import { connect } from 'react-redux'
import { I18nProvider } from '@lingui/react'
import { changeLanguage, initSitename } from '../../redux/intl'

import moment from 'moment'

class I18nLoader extends React.Component {

    static displayName = 'I18nLoader'
    
    state = {
        catalogs: {}
    }

    loadCatalog = async (language) => {
        initSitename()
        const catalog = await import(
            /* webpackChunkName: "i18n-[index]" */
            `@lingui/loader!../../locales/${language}/messages.json`)

        this.setState(state => ({
            catalogs: {
                ...state.catalogs,
                [language]: catalog
            }
        }))
    }

    componentDidMount() {
        this.loadCatalog(this.props.language)
        const lang = document.querySelector('html').getAttribute('site-lang')
        if(lang !== this.props.language){
            this.props.changeLanguage(lang)
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        const { language } = nextProps
        const { catalogs } = nextState

        if (language !== this.props.language && !catalogs[language]) {
            moment.locale(language)
            this.loadCatalog(language)
            return false
        }

        return true
    }

    render() {
        const { children, language } = this.props
        const { catalogs } = this.state

        if (!catalogs[language]) return null;
        
        return (
            <I18nProvider language={language} catalogs={catalogs}>
                {children}
            </I18nProvider>
        )
    }
}

export default connect(({ intl: { language, } }) => ({
    language
}), dispatch => ({
    changeLanguage: lang => dispatch(changeLanguage(lang)),
}))(I18nLoader)