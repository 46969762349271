import { get, post, postMultipart } from './_core'

export function countries() {
    return get('/countries', null, true)
}

export function cities(countryId) {
    return get(`/cities/${countryId}`, null, true).then(result=>result.values)
}

export function updates({ gaClientId }={}) {
    return post('/updates', {
        gaClientId
    })
}

export function users(ids) {
    return post('/users', {
        ids: Array.isArray(ids) ? ids : [ids]
    })
}

export function dashboard() {
    return get('/dashboard')
}

export function submitFeedback({ reason, email, gsm, text, files }, token) {
    const form = {
        reason,
        email,
        gsm: reason === 2 ? gsm : undefined,
        text,
        width: window.innerWidth,
        height: window.innerHeight,
        colorDepth: window.screen.colorDepth,
        navigatorJE: 0,
        navigatorCE: 1,
        token: token
    };

    for(const key in files) {
        form[`file${key}`] = files[key]
    }
    return postMultipart('/contacts', form)
}

export function currentIpLocation(){
    return get('/current_ip_location')
}

export function getRecaptcha(type) {
    return get('/captcha/get', {
        type
    })
}

export function getSiteName(){
    return get('/get_site_name')
}